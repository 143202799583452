import 'corejs-typeahead/dist/typeahead.jquery.min.js';
import Bloodhound from 'corejs-typeahead/dist/bloodhound.min.js';
window.Bloodhound = Bloodhound;
import Handlebars from 'handlebars/dist/handlebars.min.js';
window.Handlebars = Handlebars;
import levenshtein from './utils.js';

const sortNamesByRelevance = function(a, b) {
  var inputString = $('#siteSearch .tt-input').val();
  var levenshteinDistance = levenshtein(a.name, inputString) - levenshtein(b.name, inputString);
  var weight = (a.weight >= 0 && b.weight >= 0) ? b.weight - a.weight : 0;
  return levenshteinDistance + weight;
};

function defaultSearchPanel() {
  const xhr = new XMLHttpRequest();
  xhr.open('GET', '/api/search-panel?market=' + context.market.slug);
  xhr.setRequestHeader('Content-Type', 'application/json');
  xhr.onload = function() {
    const response = JSON.parse(xhr.responseText);
    if (xhr.status === 200) {
      if (response.categories.length > 0 || response.articles.length > 0) {
        let categoryRow = Handlebars.compile(`
          <ul class="p-0" style="list-style:none;">
            {{#each categories}}
              <li class="category-row">
                {{#if this.icon}}<i class="fas fa-{{this.icon}} fa-fw"></i> {{/if}}
                <a href="{{this.url}}">{{this.name}}</a>
              </li>
            {{/each}}
          </ul>
        `);
        let categoryList = categoryRow({ categories: response.categories });

        let articleRow = Handlebars.compile(`
          <ul class="p-0" style="list-style:none;">
            {{#each articles}}
              <li class="row article-row">
                <div class="col-sm-3 d-none d-sm-block">
                  <a href="{{this.absolute_url}}"><img style="max-width:100%;" src="{{this.image.image.xsmall}}"></a>
                </div>
                <div class="col-12 col-sm-9 ps-0">
                  <a href="{{this.absolute_url}}">{{this.headline}}</a>
                </div>
              </li>
            {{/each}}
          </ul>
        `);
        let articleList = articleRow({ articles: response.articles });


        $('.default-search-panel .loading-spinner').hide();
        $('.default-search-panel .category-list').html(categoryList);
        $('.default-search-panel .article-list').html(articleList);
        $('.default-search-panel').show();
      } else {
        $('.default-search-panel').hide();
      }
    } else {
      // error
    }
  };
  xhr.send();
}

function searchDropdown(q, sync, async) {
  if (q === '') {
    // defaultSearchPanel();
  } else {
    $('.default-search-panel').hide();
    return siteSearch.search(q, sync, async);
  }
}

var siteSearch = new Bloodhound({
  datumTokenizer: Bloodhound.tokenizers.obj.whitespace('name'),
  queryTokenizer: Bloodhound.tokenizers.whitespace,
  remote: {
    url: '/api/search/',
    replace: function(url, query) {
      const sortGroup = $('#siteSearch .typeahead').data('sortGroup');
      const siteSection = $('#siteSearch .typeahead').data('siteSection');
      let params = {
        'q': query,
        'market': context.market.slug
      };
      if (sortGroup) {
        params.sort = sortGroup;
      }
      if (siteSection) {
        params.site_section = siteSection;
      }
      return url + '?' + $.param(params);
    },
    wildcard: '%QUERY',
    transform : function(response) {
      var sortedResults = [];
      for (var i = 0; i < response.results.length; i++) {
        if(response.results[i].data.length > 0) {
          sortedResults.push({
            'header': response.results[i].header
          });
          sortedResults = sortedResults.concat(response.results[i].data);
        }
      }
      return sortedResults;
    }
  },
  identify: function(obj) {
      return obj.name;
  }
});

var cursorIndex;
var previousIndex;
$('#siteSearch .typeahead').typeahead({
  hint: true,
  highlight: true,
  minLength: 0
},
{
  name: 'Search',
  source: searchDropdown,
  limit: 25,
  display: 'name',
  templates: {
    empty: "<div>Sorry, we couldn't find any results for your search.</div>",
    suggestion: Handlebars.compile('<div>{{#if header}}<h2>{{ header }}</h2>{{else}}<a href="{{ url }}">{{/if}}{{ name }}{{#if secondary}} <span class="typeahead-secondary">{{secondary}}</span>{{/if}}{{#if url}}</a>{{/if}}</div>', {noEscape: true})
  }
}).bind('typeahead:select', function(ev, suggestion) {
  if (suggestion.url) {
    window.location.href = suggestion.url;
  }
}).bind('typeahead:cursorchange', function(ev, suggestion) {
  var cursorPosition = $(ev.currentTarget).closest('.twitter-typeahead').find('.tt-cursor');
  cursorIndex = cursorPosition.index();
  if (suggestion && suggestion.header) {
    var e = $.Event("keydown");
    e.which = (previousIndex && cursorIndex < previousIndex) ? 38 : 40; // 38 = up : 40 = down
    $(ev.currentTarget).trigger(e);
  }
  previousIndex = cursorIndex;
}).bind('typeahead:asyncreceive', function (ev, dataset, query) {
  // if query is more than three characters, push to dataLayer
  if (query.length >= 3) {
    dataLayer.push({
      event: "userSearch",
      searchTerms: query
    });
  }
}).on('keypress', function(ev) {
  if ($(this).val() && ev.which == 13 /* 13 = ENTER */) {
    if (context.market.slug) {
      window.location.href = '/' + context.market.slug + '/search?q=' + encodeURIComponent($(this).val());
    } else {
      window.location.href = '/search?q=' + encodeURIComponent($(this).val());
    }
  }
}).on('focus', function(ev) {
  if (!$(this).val() && $.trim($('.default-search-panel .category-list').html()) !== '' && $.trim($('.default-search-panel .article-list').html()) != '') {
    $('.default-search-panel').show();
  }
}).on('blur', function(ev) {
  $('.default-search-panel').hide();
});

$('.default-search-panel').on('mousedown', function(ev) {
  ev.preventDefault();
});
