import _ from 'lodash';

function slideItemPrevious() {
    var slider_port = $(this).parent().siblings(".wrapping-list");
    var slider_move_pos = $(slider_port).scrollLeft() - $(slider_port).width();
    $(slider_port).animate({ scrollLeft: slider_move_pos });
    return false;
}
function slideItemNext() {
    var slider_port = $(this).parent().siblings(".wrapping-list");
    var slider_move_pos = $(slider_port).scrollLeft() + $(slider_port).width();
    $(slider_port).animate({ scrollLeft: slider_move_pos });
    return false;
}
var sliderHoverState = new Array();
function monitorSlidePosition(eventType) {

    if (typeof event === 'object' && event !== null) {
        var eventType = event.type;
    }
    var sliderid = $(this).data("slider-instance");
    switch (eventType) {
        case "mouseout":
            sliderHoverState[sliderid] = false;
            break;
        case "mouseover":
            sliderHoverState[sliderid] = true;
    }
    if (!$(this).hasClass("wrapping-list")) {
        var slider_port = $(this).find(".wrapping-list");
    } else {
        var slider_port = $(this);
    }
    
    var slider_port_width = $(slider_port).outerWidth();
    var slider_width = $(slider_port)[0].scrollWidth;
    var slider_current_pos = $(slider_port).scrollLeft();
    var slider_prev = $(slider_port).parent().find(".scroll-link.previous");
    var slider_next = $(slider_port).parent().find(".scroll-link.next");
    if (slider_width <= slider_port_width || eventType == 'mouseout' || eventType == 'init') {
        $(slider_prev).fadeOut("fast");
        $(slider_next).fadeOut("fast");
    } else if (sliderHoverState[sliderid]) {
        if (slider_current_pos <= 0) {
            // at beginning, hide previous arrow
            $(slider_prev).fadeOut("fast");
            $(slider_next).fadeIn("fast");
        } else if ((slider_current_pos + slider_port_width) >= slider_width) {
            // at end, hide next arrow
            $(slider_prev).fadeIn("fast");
            $(slider_next).fadeOut("fast");
        } else {
            // show both
            $(slider_prev).fadeIn("fast");
            $(slider_next).fadeIn("fast");
        }
    }
}
$(function () {
  // bind click events, bind scroll event, fire init event
  $(".item-carousel .wrapping-list").each(
      function(index,value) {
          var sliderid = index+"."+Date.now();
          $(this).data("slider-instance",sliderid);
      }
  )
  $(".item-carousel .scroll-link a.scroll-previous").on('click', slideItemPrevious);
  $(".item-carousel .scroll-link a.scroll-next").on('click', slideItemNext);
  $(".item-carousel .wrapping-list").on('scroll', _.throttle(monitorSlidePosition, 100));
  $(".item-carousel .outer-list").on('mouseenter mouseleave', monitorSlidePosition);
  $(".item-carousel .wrapping-list").each(
      function() {
          monitorSlidePosition.call(this,'init');
      }
    );
})
