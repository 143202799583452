function setAccountMenu() {
  $.ajax({
    method: 'GET',
    url: `${context.market.publication_host.replace(/www\./, 'post.')}/api/v1/members/account-menu`,
    headers: { 'X-CSRF-Token': Cookies.get('csrfToken'), 'Session-Key': Cookies.get('session_key') },
    contentType: 'application/json',
    dataType: 'json',
    success: function(response) {
      $('#topNavAccountMenu').html(response.topNavHtml);
      $('#mobileNavAccountMenu').html(response.mobileNavHtml);
    },
    error: function(XMLHttpRequest, textStatus, errorThrown) {
      console.log(`${textStatus}, ${errorThrown}`);
    }
  });
}
export function setLoginBar() {
  var sessionKey = Cookies.get('session_key');
  if (sessionKey) {
    $.ajax({
      method: 'GET',
      url: `${context.market.publication_host.replace(/www\./, 'post.')}/api/v1/members/validate-session`,
      headers: { 'X-CSRF-Token': Cookies.get('csrfToken'), 'Session-Key': Cookies.get('session_key') },
      contentType: 'application/json',
      dataType: 'json',
      data: {
        "return": "messages"
      },
      success: function(response) {
        if (response.sessionIsValid) {
          setAccountMenu();
        } else {
          // delete cookies
          Cookies.set('session_key', '', { path: '/', expires:-1, domain: context.market.publication_host.replace(/^https:\/\/www/, '') });
          Cookies.set('strprefs', '', { path: '/', expires:-1, domain: context.market.publication_host.replace(/^https:\/\/www/, '') });
        }
      },
      error: function(XMLHttpRequest, textStatus, errorThrown) {
        console.log(`${textStatus}, ${errorThrown}`);
      }
    });
  }
}
