// things to do when the DOM is loaded

document.addEventListener("DOMContentLoaded", function() {
    // override twitter share links to open in a little popup
    var popupWindowShareLinks = document.querySelectorAll('a.share-link.new-window');
    function twShareClickHandler(e, el) {
        e.preventDefault();
        idx.utils.popupWindow(el.href, 500, 300);
    }
    Array.from(popupWindowShareLinks).forEach(function(el) {
        el.addEventListener('click', function(e) { twShareClickHandler(e, el); });
    });

    var selectJumpMenus = document.querySelectorAll('select.nav-jump-select');
    Array.from(selectJumpMenus).forEach(function(el) {
        el.addEventListener('change', function(e) {
            if ($(el).val() != '') {
              window.location.href = $(el).val();
            }
        });
    });

  // main nav toggler
  let topNavToggler = document.querySelector('.top-nav .navbar-toggler');
  if (topNavToggler) {
    topNavToggler.addEventListener('click', function () {
      document.querySelector('.animated-nav-icon').classList.toggle('open');
    });
  }
});
