/*
  this is the "foot" entry point for webpack
  files imported here are compiled into head.js or head.css
  see webpack.config.js for configuration
*/

// VENDOR STUFF
import popper from 'popper.js';
import moment from 'moment';
window['moment'] = moment;
import stickyfill from 'stickyfilljs/dist/stickyfill.min.js';
window['stickyfill'] = stickyfill;

// OUR STUFF
import * as idxNav from './js/nav.js';
window.idx.nav = idxNav;
import './js/site-search.js';
import './js/item-slider.js';
import * as Utils from './js/utils.js';
window.idx.utils = Utils;

// IMPORT VUE_APPS DEFINED IN VIEWS.PY
if(context && context.vueApps) {
  for(var i=0; i<context.vueApps.length; i++) {
    import('./js/' + context.vueApps[i]).then((module) => {
        module.default();
    })
  }
}

import './js/dom-loaded.js';
